<div class="backToCartBanner" id="top">
  <img *ngIf="screen.isDesktop" src="/assets/images/logo.svg" />
  <img *ngIf="screen.isMobile" src="/assets/images/m-logo.svg" />
  <svg viewbox="0 0 32 32" (click)="goToMVHome()">
    <use xlink:href="#icon-close"></use>
  </svg>
</div>

<div class="order-container" *ngIf="resourcesStore.isLoaded()">
  <app-page-title [mainTitle]="'SURPLUS.TITLE' | translate"> </app-page-title>
  <div class="flex ai-center j-center" *ngIf="payment && payment?.status == 'Paid'">
    <div class="hostess-order-frame ai-center">
      <div class="order-paid flex column j-start ai-center ac-center gap-10">
        <h1 class="canela center">{{ "SURPLUS.CONFIRMATION.TITLE" | translate }}</h1>
        <p class="m-center margin-tb-10">
          {{ "SURPLUS.CONFIRMATION.BODY" | translate }}
        </p>
        <div class="flex j-center rowCTA">
          <app-wavedlink
            [linkText]="resourcesStore.i18n().resources.checkoutConfirmation.buttonLabel"
            [externalUrl]="getLogoUrl()"></app-wavedlink>
        </div>
      </div>
    </div>
  </div>
  <app-order-payment-pending *ngIf="!payment ||  payment?.status != 'Paid'"></app-order-payment-pending>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
