import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { MyaccesComponent } from './components/profil/ components/myacces/myacces.component';
import { MycommunicationsComponent } from './components/profil/ components/mycommunications/mycommunications.component';
import { MydatasComponent } from './components/profil/ components/mydatas/mydatas.component';
import { ProfilComponent } from './components/profil/profil.component';
import { StatusComponent } from './components/status/status.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { PointEncodingComponent } from './components/point-encoding/point-encoding.component';
import { DelegateComponent } from './components/delegate/delegate.component';
import { OrdersListComponent } from './components/order/orders-list/orders-list.component';
import { OrderComponent } from './components/order/order/order.component';
import { ClientCodeComponent } from './components/client-code/client-code.component';
import { BoosterComponent } from './components/booster/booster.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { StaffManagerComponent } from './components/staff-manager/staff-manager.component';
import { ManageDocumentsComponent } from './components/staff-manager/manage-documents/manage-documents.component';
import { DocumentsListingComponent } from './components/staff-manager/manage-documents/documents-listing/documents-listing.component';
import { EditDocumentComponent } from './components/staff-manager/manage-documents/edit-document/edit-document.component';
import { ContextStore } from '../../stores/context.store';
import { ResourcesStore } from '../../stores/resources.store';
import { CookieService } from 'ngx-cookie-service';
import { getAreaByUrl } from '../../core/utils/filter.utils';
import { environment } from '../../../environments/environment';
import { from, map, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { match } from '@formatjs/intl-localematcher';

const AutoSetContext = async (route: ActivatedRouteSnapshot) => {
  if (!route.params.id) return true;

  const contextStore = inject(ContextStore);
  const resourcesStore = inject(ResourcesStore);
  const cookie = inject(CookieService);
  const router = inject(Router);
  const params = route.params;
  const area = getAreaByUrl(window.location.origin);

  const availableLanguages = environment.allowedLocales[area];
  const languages = availableLanguages?.map(l => l.split('-')[0]) ?? [];

  if (params.id && availableLanguages.includes(params.id)) {
    const locale = params.id;

    let region = cookie.get('v_region');

    if (!region) region = locale;

    return from(contextStore.setLocale({ locale, region })).pipe(
      switchMap(() => from(resourcesStore.loadResources(locale))),
      map(() => true),
      catchError(err => {
        console.log('Error Loading Resources : ', err);
        return of(false);
      })
    );
  } else {
    let locale = cookie.get('v_locale')?.replace('_', '-');

    if (locale && availableLanguages.includes(locale)) {
      await router.navigateByUrl('/' + locale);
    } else {
      locale = match(navigator.languages, languages, availableLanguages[0]);
      await router.navigateByUrl('/' + locale);
    }
  }
};
//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// showHeaderMobileOnly: true -- Allows you to switch header to header-light except for mobile
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: { breadcrumb: 'MENU.HOME', showBreadCrumb: false },
    component: HomeComponent,
  },
  {
    path: 'status',
    data: { breadcrumb: 'MENU.STATUS' },
    component: StatusComponent,
  },
  {
    path: 'wallet',
    data: { breadcrumb: 'MENU.WALLET' },
    component: WalletComponent,
  },
  {
    path: 'delegate',
    data: { breadcrumb: 'MENU.DELEGATE' },
    component: DelegateComponent,
  },
  {
    path: 'point-encoding',
    data: { breadcrumb: 'MENU.POINTENCODING' },
    component: PointEncodingComponent,
  },
  // {
  //   path: 'point-evolution',
  //   data: {breadcrumb: 'MENU.POINTS'},
  //   component: PointEvolutionComponent,
  // },
  {
    path: 'orders',
    data: { breadcrumb: 'MENU.ORDERS.ORDERS' },
    component: OrdersListComponent,
  },
  {
    path: 'orders/:id/:value',
    data: { breadcrumb: 'MENU.ORDERS.ORDERS' },
    component: OrderComponent,
  },
  {
    path: 'referral',
    data: { breadcrumb: 'MENU.BOOSTER' },
    component: BoosterComponent,
  },
  {
    path: 'profile',
    data: { breadcrumb: 'MENU.PROFILE' },
    component: ProfilComponent,
    canActivateChild: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: '',
        redirectTo: 'data',
        pathMatch: 'full',
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.INFORMATIONS' },
        path: 'data',
        component: MydatasComponent,
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.ACCES' },
        path: 'access',
        component: MyaccesComponent,
      },
      {
        data: { breadcrumb: 'MENU.PROFILE.COMMUNICATION' },
        path: 'communication',
        component: MycommunicationsComponent,
      },
    ],
  },
  {
    path: 'client-code',
    data: { breadcrumb: 'MENU.CLIENT_CODE' },
    component: ClientCodeComponent,
  },

  {
    path: 'manager',
    canActivate: [AutoSetContext],
    component: StaffManagerComponent,
    data: {
      breadcrumb: 'MENU.DEMO',
      showJumbotron: false,
      showNewsletter: false,
      showFooterMenu: false,
      showBreadCrumb: false,
    },
  },
  {
    path: 'manage/:category',
    canActivate: [AutoSetContext],
    canActivateChild: [AutoSetContext],
    component: ManageDocumentsComponent,
    children: [
      {
        path: '',
        component: DocumentsListingComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
      {
        path: ':action',
        component: EditDocumentComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
      {
        path: ':action/:documentId',
        component: EditDocumentComponent,
        data: {
          breadcrumb: 'MENU.DEMO',
          showJumbotron: false,
          showNewsletter: false,
          showFooterMenu: false,
          showBreadCrumb: false,
        },
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      showBreadCrumb: false,
      showJumbotron: false,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
