import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { getAreaByUrl } from '../utils/filter.utils';
import { ContextStore } from '../../stores/context.store';
import { ResourcesStore } from '../../stores/resources.store';
import { match } from '@formatjs/intl-localematcher';

@Injectable({
  providedIn: 'root',
})

//TODO check region with context / language for all countries
export class LanguageResolver {
  readonly contextStore = inject(ContextStore);
  readonly resourcesStore = inject(ResourcesStore);
  readonly cookie = inject(CookieService);
  readonly router = inject(Router);

  async resolve(route: ActivatedRouteSnapshot) {
    const params = route.params;
    const area = getAreaByUrl(window.location.origin);

    const availableLanguages = environment.allowedLocales[area];
    const languages = availableLanguages?.map(l => l.split('-')[0]) ?? [];

    if (params.id && availableLanguages.includes(params.id)) {
      const selectedLang = params.id;
      let region = this.cookie.get('v_region');

      if (!region) region = selectedLang;

      if (selectedLang == 'nl-be' && (region == 'fr-lu' || region == 'fr-be')) region = selectedLang;
      else if (selectedLang == 'fr-be' && (region == 'nl-be' || region == 'nl-nl')) region = selectedLang;

      try {
        await this.contextStore.setLocale({ locale: selectedLang, region });
        await this.resourcesStore.loadResources(selectedLang);

        return true;
      } catch (err) {
        return false;
      }
    } else {
      let locale = this.cookie.get('v_locale')?.replace('_', '-');

      if (locale && availableLanguages.includes(locale)) {
        await this.router.navigate(['/' + locale]);
      } else {
        locale = match(navigator.languages, languages, availableLanguages[0]);
        await this.router.navigate(['/' + locale]);
      }
    }
  }
}
