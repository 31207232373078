import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  CartResponse,
  DemoStatusObject,
  GetCartAvailableDeliveryTypesResponse,
  GetCartAvailablePaymentTypesResponse,
  DemoHostessGiftsResponse,
  GetDemoResponse,
  ProductSearchModel,
} from '@victoria-company/agora-client';
import { CheckoutType } from '../core/enums/checkout-type.enum';
import { CartService } from '../core/services/V2/cart.service';
import { StorageService } from '../core/services/storage.service';
import { UserStore } from './user.store';
import { ContextStore } from './context.store';
import { CheckoutOrValidationProgressionStep } from '../core/enums/checkoutOrValidationProgressionStep.enum';
import { SocketService } from '../core/services/V2/sockets.service';
import { DemoService } from '../core/services/V2/demo.service';
import { SaveGiftsRequest } from '../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';
import { getProductsFromGiftsResponse } from '../core/utils/common.utils';
import { CatalogService } from '../core/services/V2/catalog.service';
import { DemoHostessGifts } from './demo.store';
import { PaymentsService } from '../core/services/V2/payments.service';

export interface CartState {
  demo: GetDemoResponse;
  cart: CartResponse;
  hostessGifts: DemoHostessGifts;
  deliveryOptions: GetCartAvailableDeliveryTypesResponse;
  paymentsOptions: GetCartAvailablePaymentTypesResponse;
  isLoaded: boolean;
  hasItems: boolean;
  itemsCount: number;
  addToCartSelectProductVariantModal: {
    isOpened: boolean;
    product?: ProductSearchModel;
    selectedVariantId?: string;
    isAddingToCart: boolean;
  };
  confirmDeleteItemFromCartModal: {
    isOpened: boolean;
    productVariantId?: string;
    cartId: string;
    cartItemId: string;
    isDeletingFromCart: boolean;
  };
  deliveryInfoModal: {
    isOpened: boolean;
  };
}

export const initialState: CartState = {
  cart: null,
  demo: null,
  hostessGifts: null,
  deliveryOptions: null,
  paymentsOptions: null,
  isLoaded: false,
  hasItems: false,
  itemsCount: 0,
  addToCartSelectProductVariantModal: {
    isOpened: false,
    product: null,
    selectedVariantId: null,
    isAddingToCart: false,
  },
  confirmDeleteItemFromCartModal: {
    isOpened: false,
    productVariantId: null,
    cartId: null,
    isDeletingFromCart: false,
    cartItemId: null,
  },
  deliveryInfoModal: {
    isOpened: false,
  },
};

export const CartStore = signalStore(
  { providedIn: 'root' },
  withDevtools('cart'),
  withState(initialState),
  withComputed(store => ({
    itemsCount: computed(() => store.cart()?.cartItems?.length ?? 0),
    hasItems: computed(() => store.cart()?.cartItems?.length > 0),
    checkoutType: computed(() =>
      store.demo()?.demo && store.demo()?.demo?.status != DemoStatusObject.Closed && store.demo()?.demo?.status != DemoStatusObject.Canceled && store.demo()?.roles?.length > 0
        ? CheckoutType.DEMO
        : CheckoutType.ESHOP
    ),
  })),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      demoService = inject(DemoService),
      userStore = inject(UserStore),
      cartService = inject(CartService),
      storageService = inject(StorageService),
      catalogService = inject(CatalogService)
    ) => ({
      async loadCartFromApi() {
        const anonymousCartId = storageService.getAnonymousCartId();
        const cart = userStore.isAuthenticated() ? await cartService.getCart() : anonymousCartId ? await cartService.getCartByCartId(anonymousCartId) : null;

        if (!cart) {
          patchState(store, () => ({ isLoaded: true }));
          return;
        }

        // TODO : REMOVE THIS FROM HERE
        const demoResponse = cart.demoId ? await cartService.getCartDemo(cart.demoId) : null;
        // TODO : REMOVE THIS FROM HERE : LOAD PAYMENTS AND DELIVERY OPTIONS WHEN ITS NEED ON A COMPONENT
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());
        const deliveryOptions = await cartService.getDeliveryOptions(cart.id);
        const gifts =
          demoResponse?.roles?.includes('Hostess') && demoResponse?.demo?.status == 'Opened' ? await demoService.getGiftsForDemo(cart?.demoId, contextStore.locale()).catch(err => null) : null;

        patchState(store, () => ({
          isLoaded: true,
          demo: demoResponse,
          cart: {
            ...cart,
          },
          hostessGifs: gifts,
          paymentsOptions,
          deliveryOptions,
          addToCartSelectProductVariantModal: {
            isOpened: false,
            isAddingToCart: false,
            product: null,
            selectedVariantId: null,
          },
          confirmDeleteItemFromCartModal: {
            isOpened: false,
            isDeletingFromCart: false,
            productVariantId: null,
            cartId: null,
            cartItemId: null,
          },
          deliveryInfoModal: {
            isOpened: false,
          },
        }));
      },
      async updateCartFromSocket(cart: CartResponse) {
        if (cart.status == 'Finalized' || cart.status == 'Confirmed') {
          patchState(store, {
            ...initialState,
            isLoaded: true,
          });
          return;
        }

        console.warn('Cart Update From Socket');

        const demoResponse = cart.demoId ? await cartService.getCartDemo(cart.demoId) : null;
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());
        const deliveryOptions = await cartService.getDeliveryOptions(cart.id);

        patchState(store, () => ({
          paymentsOptions,
          deliveryOptions,
          cart: {
            ...cart,
          },
          demo: demoResponse,
        }));
      },
      async updateGiftsFromSocket(gifts: DemoHostessGiftsResponse, locale: string, contextId: number) {
        console.log('Update Gifts From Sockets');
        await getProductsFromGiftsResponse(gifts, locale, contextId, catalogService);
        patchState(store, { hostessGifts: gifts });
      },
      async getHostessGifts(demoCodeOrId: string, locale: string, contextId: number) {
        const gifts = await demoService.getGiftsForDemo(demoCodeOrId, locale);
        await getProductsFromGiftsResponse(gifts, locale, contextId, catalogService);
        patchState(store, { hostessGifts: gifts });
      },
      async saveHostessGift(demoCodeOrId: string, userId: string, gift: SaveGiftsRequest) {
        await demoService.saveHostessGift(demoCodeOrId, userId, gift);
      },
      async reloadCartPaymentOptions(cartId: string, locale: string) {
        const paymentsOptions = await cartService.getPaymentOptions(cartId, locale);
        patchState(store, { paymentsOptions });
      },
    })
  ),
  withMethods((store, socketService = inject(SocketService), paymentService = inject(PaymentsService)) => ({
    async subscribeToAnonymousCartUpdates(cartId: string) {
      await socketService.listenForAnonymousCartUpdates(cartId, {
        onCartChange: async cart => {
          return store.updateCartFromSocket(cart);
        },
        onReconnected: () => {
          console.log('Reconnect listen for anonymous cart updates');
          return store.loadCartFromApi();
        },
      });
    },
    async cancelPaymentIfPendingPayment() {
      const cart = store.cart();

      if (!cart) return;
      else if (cart?.status == 'PendingPayment' || cart?.status == 'Active') {
        const payment = cart?.cartPayments?.find(p => p.result == 'Open' && p.type == 'Digital');
        if (payment) await paymentService.cancelPayment(payment.paymentId).catch(err => console.log('Cancel Payment Error : ', err));
      }
    },
  })),
  withMethods((store, cartService = inject(CartService), storageService = inject(StorageService)) => ({
    async loadCart() {
      const anonymousCartId = storageService.getAnonymousCartId();

      if (anonymousCartId) {
        await store.subscribeToAnonymousCartUpdates(anonymousCartId);
      }
      await store.loadCartFromApi();
    },
    setCartLoaded() {
      patchState(store, { isLoaded: true });
    },
    openAddToCartVariantSelectionModal(product: ProductSearchModel) {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isOpened: true,
          product,
          selectedVariantId: product.variants[0].id,
        },
      }));
    },
    openDeleteFromCartConfirmationModal(cartId: string, productVariantId: string, cartItemId: string) {
      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isOpened: true,
          cartId,
          cartItemId,
          productVariantId,
        },
      }));
    },
    cancelAddToCartVariantSelectionModal() {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isOpened: false,
          product: null,
          selectedVariantId: null,
        },
      }));
    },
    cancelDeleteFromCartConfirmationModal() {
      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isOpened: false,
          isDeletingFromCart: false,
          productVariantId: null,
          cartId: null,
        },
      }));
    },
    changeSelectedProductVariantInSelectionModal(selectedVariantId: string) {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          selectedVariantId,
        },
      }));
    },
    openDeliveryInfo() {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: true,
        },
      }));
    },
    closeDeliveryInfo() {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: false,
        },
      }));
    },
    async getDeliveryOptions(cartId: string) {
      const deliveryOptions = await cartService.getDeliveryOptions(cartId);

      patchState(store, () => ({
        deliveryOptions,
      }));
    },
  })),
  withMethods((store, cartService = inject(CartService)) => ({
    async deleteItemFromCart(cartId: string, cartItemId: string) {
      await cartService.delete(cartItemId, cartId);

      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isDeletingFromCart: false,
          isOpened: false,
        },
      }));
    },
    async updateItemQuantity(productVariantId: string, newQuantity: number, cartId: string) {
      await cartService.update(cartId, productVariantId, undefined, newQuantity);
    },
    async updateItemSize(cartItemId: string, newProductVariantId: string, cartId: string) {
      await cartService.update(cartId, cartItemId, newProductVariantId);
    },
    async requestDelegateApproval(cartId: string) {
      await cartService.requestDelegateApproval(cartId);
    },
    async updateCartItemLinks(links: number, cartItemId: string, cartId: string) {
      await cartService.updateCartItemLinks(links, cartItemId, cartId);
    },
  })),
  withMethods((store, contextStore = inject(ContextStore), cartService = inject(CartService), storageService = inject(StorageService)) => ({
    async createCart(demoId?: string) {
      const createResponse = await cartService.create(contextStore.contextId(), demoId);
      const cart = createResponse?.cart;

      if (!cart.userId) {
        storageService.setAnonymousCartId(cart.id);
        await store.subscribeToAnonymousCartUpdates(cart.id);
      }

      const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());

      patchState(store, () => ({
        isLoaded: true,
        cart: {
          ...cart,
        },
        paymentsOptions,
      }));
    },
    stepChanged(step: CheckoutOrValidationProgressionStep) {
      patchState(store, () => ({
        cart: {
          ...store.cart(),
          userNavigationStep: step,
        },
      }));
    },
  })),
  withMethods((store, cartService = inject(CartService)) => ({
    async addProductVariantToCart(cartId: string, variantId: string) {
      if (!cartId && (!store.cart() || !store.cart().id)) {
        await store.createCart();
        cartId = store.cart().id;
      }

      await cartService.add(variantId, cartId);

      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isAddingToCart: false,
          isOpened: false,
        },
      }));
    },
  }))
);
