<div class="modal-container" [id]="'#' + id">
  <div class="modal + {{ modalClass }}" [class.search-delegate]="template == 'searchDelegate' || template == 'selectDelegate'" *ngIf="resourcesStore.isLoaded()" [style]="{ width: width }">
    <div *ngIf="isClosable" class="modal-icon icon peach" (click)="modal.close('#' + id)">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div *ngIf="viewNextStep" class="flex cursor" (click)="stepBack()">
      <svg viewBox="0 0 32 32" class="icon">
        <use xlink:href="#icon-arrow-left-long"></use>
      </svg>
      {{ resourcesStore.i18n().resources.popupFindConsultant.backLabel }}
    </div>
    <h1 *ngIf="title && !viewNextStep">{{ title }}</h1>
    <h1 *ngIf="titleNextStep && viewNextStep">{{ titleNextStep }}</h1>
    <ng-container
      *ngTemplateOutlet="
        {
          info: info,
          promo: promo,
          size: size,
          addCode: addCode,
          searchDelegate: searchDelegate,
          selectDelegate: selectDelegate,
          confirmDeleteWishlistItem: confirmDeleteWishlistItem,
          walletCreateVoucher: walletCreateVoucher,
          simpleSize: simpleSize,
          birthDateModal: birthDateModal,
          congratulationsModal: congratulationsModal,
          contextRedirectionModal: contextRedirectionModal,
          confirmation: confirmation
        }[template]
      ">
    </ng-container>
  </div>
</div>

<ng-template #confirmation>
  <div class="modal-body confirm-delete-wishlist-item flex column ai-center">
    <p>{{ 'CART.MODAL.CONFIRMATION.CAPTION' | translate }}</p>
    <button class="action-button btn btn-primary j-center" (click)="confirmChoice(true)">{{ 'GLOBAL.ANSWER.YES' | translate }}</button>
    <button class="action-button btn btn-primary j-center white" (click)="confirmChoice(false)">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
  </div>
</ng-template>

<ng-template #info>
  <div class="modal-body shipping">
    <p>{{ resourcesStore.i18n().resources.popupShippingOptions.description | removeHtmlTag }}</p>
    <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.popupShippingOptions.stepsDescription">
      <div class="delivery-info boder-bottom">
        <h2 class="flex ai-start">
          <span>{{ item.number }}</span
          >{{ item.stepTitle }}
        </h2>
        <p>{{ item.text }}</p>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #promo>
  <div class="modal-body promo">
    <p>{{ 'MODAL.PROMO.SUBTITLE' | translate }}</p>
    <div class="promo-form">
      <label>{{ 'MODAL.PROMO.LABEL' | translate }}</label>
      <div class="flex gap-8">
        <div [class.error]="hasError">
          <input type="text" [(ngModel)]="value" [placeholder]="'MODAL.PROMO.PLACEHOLDER' | translate" />
          <label *ngIf="hasError && errorCode == 12">{{ resourcesStore.i18n().resources.shoppingBag.bagLabel }}</label>
          <label *ngIf="hasError && errorCode == 13">{{ resourcesStore.i18n().resources.shoppingBag.backToTopLabel }}</label>
          <label *ngIf="hasError && errorCode == 14">{{ resourcesStore.i18n().resources.shoppingBag.backLabel }}</label>
          <label *ngIf="hasError && errorCode == 15">{{ resourcesStore.i18n().resources.shoppingBag.backToTopLabel }}</label>
        </div>
        <button class="btn btn-primary" (click)="modal.executeAction({ actionName: 'validateCode', value: value })">{{ 'GLOBAL.ACTION.ADD' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #size>
  <div class="modal-body size" *ngIf="!viewNextStep">
    <p>{{ resourcesStore.i18n().resources.popupPickSize.description | removeHtmlTag }}</p>
    <div class="promo-form">
      <h3 class="lightbold">{{ item?.name ?? item?.productGroup?.name }}</h3>
      <div class="flex column gap-15">
        <div class="flex gap-8 ai-center">
          <select class="control-select" [(ngModel)]="articleSelected">
            <option *ngFor="let opt of articleSizes" [value]="opt.id" [disabled]="!opt.canSelect" [class.barre]="!opt.canSelect">{{ opt.value }}</option>
          </select>
          <div class="flex cursor ai-center" (click)="goNextStep()">
            <svg viewBox="0 0 32 32" class="icon">
              <use xlink:href="#icon-size"></use>
            </svg>
            <span class="mini-text">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}</span>
          </div>
        </div>

        <button class="btn btn-primary j-center" (click)="selectItem()">{{ resourcesStore.i18n().resources.popupPickSize.addToBagLabel }}</button>
      </div>
    </div>
  </div>
  <div class="modal-body modal-size-guide" *ngIf="viewNextStep">
    <!-- Tab Area -->
    <div class="flex j-start selection scrollable">
      <div
        *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"
        class="tab tab-auto flex center-all no-wrap selection-title"
        [class.selected]="selectedTab == i.toString()"
        (click)="switchTab(i.toString())">
        {{ item.tabLabel }}
      </div>
    </div>
    <!-- Tab Content Area-->
    <div class="search-form">
      <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">
        <div class="flex column gap-8 tab-details" *ngIf="selectedTab == i.toString()">
          <p>{{ item.description }}</p>
          <div class="flex ai-start gap-20 m-column">
            <figure *ngIf="item.imagePoster.length > 0" class="flex-1">
              <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />
            </figure>
            <div class="flex-1 flex column table-container">
              <div class="flex j-space-around">
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>
                  </div>
                </div>
              </div>
              <div class="flex gap-10 j-space-around table-size">
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col1 }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col2 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #simpleSize>
  <div class="modal-body modal-size-guide">
    <!-- Tab Area -->
    <div class="flex j-start selection scrollable">
      <div
        *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"
        class="tab tab-auto flex center-all no-wrap selection-title"
        [class.selected]="selectedTab == i.toString()"
        (click)="switchTab(i.toString())">
        {{ item.tabLabel }}
      </div>
    </div>
    <!-- Tab Content Area-->
    <div class="search-form">
      <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">
        <div class="flex column gap-8 tab-details" *ngIf="selectedTab == i.toString()">
          <p>{{ item.description }}</p>
          <div class="flex ai-start gap-20 m-column">
            <figure *ngIf="item.imagePoster.length > 0" class="flex-1">
              <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />
            </figure>
            <div class="flex-1 flex column table-container">
              <div class="flex j-space-around">
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>
                  </div>
                </div>
              </div>
              <div class="flex gap-10 j-space-around table-size">
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col1 }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col2 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #addCode>
  <div class="modal-body size">
    <p>{{ resourcesStore.i18n().resources.popupFindHomeparty.description | removeHtmlTag }}</p>
    <div class="promo-form">
      <div class="flex column gap-8">
        <div [class.error]="hasError">
          <input type="text" [(ngModel)]="value" placeholder="ex. 1DF34" />
          <label *ngIf="hasError && errorCode == 0">{{ resourcesStore.i18n().resources.resetpassword.shortDescription }}</label>
          <label *ngIf="hasError && errorCode == 2">{{ resourcesStore.i18n().resources.checkoutConsultant.homepartyCodeNotActive }}</label>
          <label *ngIf="hasError && errorCode == 3">{{ resourcesStore.i18n().resources.checkoutConsultant.homepartyCodeNotValid }}</label>
        </div>
        <button class="btn btn-primary j-center" (click)="modal.executeAction({ actionName: 'validateDemoCode', value: value })">
          {{ resourcesStore.i18n().resources.checkoutPayment.buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchDelegate>
  <div class="modal-body search-delegate">
    <div class="flex j-space-between selection">
      <div class="tab flex center-all flex-1 selection-title" [class.selected]="selectedTab == 'localisation'" (click)="switchTab('localisation')">
        {{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCode }}
      </div>
      <div class="tab flex center-all flex-1 selection-title" [class.selected]="selectedTab == 'name'" (click)="switchTab('name')">
        {{ resourcesStore.i18n().resources.popupFindConsultant.tabName }}
      </div>
    </div>
    <div class="search-form">
      <!-- Localisation Search Tab  -->
      <div class="flex column gap-8" *ngIf="selectedTab == 'localisation'">
        <p class="text_large">{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodeDescription }}</p>
        <div class="form-block">
          <label>{{ resourcesStore.i18n().resources.popupFindConsultant.streetLabel }}</label>
          <input type="text" [(ngModel)]="formData.adress" />
        </div>
        <div class="flex gap-20 postal" [class.error]="hasFormError && !formData.zip">
          <div class="form-block">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodePlaceholder }}</label>
            <input type="text" [(ngModel)]="formData.zip" />
            <div class="form-error-container" *ngIf="hasFormError && !formData.zip">
              {{ resourcesStore.i18n().resources.popupFindConsultant.postalCodeLabelError }}
            </div>
          </div>
          <div class="form-block" [class.error]="hasFormError && !formData.city">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.cityLabel }}</label>
            <input type="text" [(ngModel)]="formData.city" />
            <div class="form-error-container" *ngIf="hasFormError && !formData.city">
              {{ resourcesStore.i18n().resources.popupFindConsultant.cityLabelError }}
            </div>
          </div>
        </div>
        <div class="form-block" [class.error]="hasFormError && !formData.country">
          <label>{{ resourcesStore.i18n().resources.popupFindConsultant.countryLabel }}</label>
          <select [(ngModel)]="formData.country">
            <option *ngFor="let opt of this.formService.getCountries()" [value]="opt.id">{{ opt.value }}</option>
          </select>
          <div class="form-error-container" *ngIf="hasFormError && !formData.country">
            {{ resourcesStore.i18n().resources.popupFindConsultant.countryLabelError }}
          </div>
        </div>
        <div *ngIf="hasError">
          <p class="error-box">{{ resourcesStore.i18n().resources.popupFindConsultant.labelInvalidGeolocation }}</p>
        </div>
        <div class="flex j-center margin-top-15">
          <button class="btn btn-primary j-center ai-center ac-center" (click)="validateFormAndCheck()">{{ resourcesStore.i18n().resources.popupFindConsultant.buttonLabel }}</button>
        </div>
      </div>
      <!-- Name search Tab -->
      <div class="flex column gap-8" *ngIf="selectedTab == 'name'">
        <p class="text_large">{{ resourcesStore.i18n().resources.popupFindConsultant.tabNameDescription }}</p>
        <div class="form-block flex rox">
          <input type="text" [(ngModel)]="formData.name" name="delegateName" placeholder="{{ resourcesStore.i18n().resources.popupFindConsultant.tabNamePlaceholder }}" />
          <button class="btn btn-primary btn-icon-only j-center no-padding-btn" (click)="validateDelegateAndCheck()">
            <svg viewBox="0 0 32 32" class="width20">
              <use xlink:href="#icon-search"></use>
            </svg>
          </button>
        </div>
        <p class="error-box" *ngIf="hasError">{{ resourcesStore.i18n().resources.popupFindConsultant.lastNameLabelError }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectDelegate>
  <div class="modal-body">
    <div class="search-form">
      <div class="flex column gap-8" *ngFor="let delegate of item">
        <div class="delegate-list order-contact-info flex row ai-center" (click)="validateDelegate(delegate)">
          <div class="img-container">
            <figure>
              <img loading="lazy" [src]="getProfilePictureOrDefault(delegate.profileImagePath)" [alternativeImage] />
            </figure>
          </div>
          <div class="teaser-container flex column flex-3 gap-10">
            <div class="header">{{ delegate.displayName | uppercase }}</div>
            <div class="location">
              <svg viewBox="0 0 32 32" class="icon peach">
                <use xlink:href="#icon-location"></use>
              </svg>
              {{ delegate.city | uppercase }}
            </div>
          </div>
          <div class="delegate_list_arrow">
            <svg viewBox="0 0 240 208">
              <use xlink:href="#icon-arrow-right-long"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="flex column gap-8" *ngIf="item?.length == 0">
        <p class="error-box">{{ resourcesStore.i18n().resources.popupFindConsultant.labelNoConsultants }}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #walletCreateVoucher>
  <div *ngIf="selectedTab === 'createVoucherTab'">
    <div class="modal-body create-voucher" *ngIf="clubInformation">
      <!-- Modal content -->
      <div class="modal-content d-only create-voucher-creation">
        <div class="wallet-summary-container flex row j-start ai-stretch ac-center">
          <div class="current-wallet-card" *ngIf="clubInformation">
            <app-card
              class="wallet-status-card"
              [data]="clubInformation"
              [cardType]="cardType.WALLET"
              [showTooltip]="true"
              [footerInformationTooltipText]="'VOUCHER.MODAL.DESCRIPTION' | translate"
              [footerInformationTooltipPosition]="'right'"></app-card>
          </div>
          <div class="flex-2 flex column j-space-between" *ngIf="clubInformation">
            <div class="flex column">
              <div>
                <h1>{{ 'WALLET.MODAL.TITLE' | translate }}</h1>
                <p class="creation-description">{{ 'WALLET.MODAL.LABEL' | translate }}</p>
              </div>
              <p *ngIf="clubInformation.walletAmount > 0" class="available" innerHTML="{{ 'WALLET.MODAL.XAVAILABLE' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
              <p
                *ngIf="clubInformation.walletAmount == 0"
                class="available"
                innerHTML="{{ 'WALLET.CREATEVOUCHER.LABEL.ALLCONSUMED' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
              <div *ngIf="clubInformation.walletAmount > 0" class="form-control flex-1 input input-amount" [class.error]="error">
                <p>{{ 'WALLET.MODAL.VOUCHERVALUE' | translate }}</p>
                <div class="flex ai-center"><input [(ngModel)]="voucherValue" type="number" min="0.01" step="any" /> €</div>
                <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
                <label *ngIf="hasError && errorCode == 4" class="label-error sticky-message">{{ 'GLOBAL.ERROR.AMOUNTINCORRECT' | translate }}</label>
                <label *ngIf="hasError && errorCode == 300" class="label-error sticky-message">{{ 'GLOBAL.ERROR.UNKNOWNCLIENT' | translate }}</label>
                <label *ngIf="hasError && errorCode != 4 && errorCode != 300" class="label-error sticky-message">{{ 'ALERT.ERROR.GLOBAL' | translate }}</label>
              </div>
            </div>
            <div>
              <div>
                <button
                  class="btn btn-primary"
                  [class.disabled]="isLoading || clubInformation.walletAmount == 0"
                  [disabled]="isLoading || clubInformation.walletAmount == 0"
                  (click)="createVoucherAction()">
                  <div *ngIf="isLoading" class="loader loader-mini"></div>
                  {{ 'WALLET.MODAL.ACTION' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content m-only create-voucher-creation">
        <a class="back-to-wallet" (click)="modal.close('#walletCreateVoucher')">
          <div class="icon icon-left peach arrow-back">
            <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-arrow-left-long"></use>
            </svg>
          </div>
          <div class="label">{{ 'VOUCHER.MODAL.BACKTOWALLET' | translate }}</div>
        </a>
        <h1>{{ 'WALLET.MODAL.TITLE' | translate }}</h1>
        <div class="flex center-all center">
          <p *ngIf="clubInformation.walletAmount > 0" class="available" innerHTML="{{ 'WALLET.MODAL.XAVAILABLE' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
          <p
            *ngIf="clubInformation.walletAmount == 0"
            class="available"
            innerHTML="{{ 'WALLET.CREATEVOUCHER.LABEL.ALLCONSUMED' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
        </div>
        <div class="input-amount">
          <p *ngIf="clubInformation.walletAmount > 0">{{ 'WALLET.MODAL.VOUCHERVALUE' | translate }}</p>
          <div *ngIf="clubInformation.walletAmount > 0" class="form-control flex-1 input input-amount" [class.error]="error">
            <div class="flex ai-center"><input [(ngModel)]="voucherValue" type="number" min="0.01" step="any" /> €</div>
            <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
          </div>
        </div>
        <div *ngIf="clubInformation.walletAmount > 0" class="flex center-all center">
          <p class="creation-description">{{ 'WALLET.MODAL.LABEL' | translate }}</p>
        </div>
        <div class="action-button">
          <button
            class="btn btn-primary m-btn-block"
            [class.disabled]="isLoading || clubInformation.walletAmount == 0"
            [disabled]="isLoading || clubInformation.walletAmount == 0"
            (click)="createVoucherAction()">
            <div *ngIf="isLoading" class="loader loader-mini"></div>
            {{ 'WALLET.MODAL.ACTION' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 'voucherCreatedTab'">
    <div class="modal-body voucher-created" *ngIf="voucherCreated">
      <!-- Modal content -->
      <div class="modal-content m-only create-voucher-created flex column ai-center">
        <h1>{{ 'GLOBAL.GREETINGS' | translate }}</h1>
        <p class="created-confirmation">{{ 'VOUCHER.MODAL.VOUCHERCREATED' | translate }}</p>
        <div class="card">
          <div class="header">
            <div class="flex row j-space-between ai-center">
              <div class="voucher-type">
                <p>{{ 'GLOBAL.VOUCHER.TYPE_' + voucherCreated.type?.toUpperCase() | translate }}</p>
              </div>
              <div class="flex row ai-center">
                <svg viewBox="0 0 14 14" class="icon peach status-icon">
                  <use xlink:href="#green-point"></use>
                </svg>
                &nbsp; {{ 'GLOBAL.STATUS.NOTUSED' | translate }}
              </div>
            </div>
          </div>
          <div class="body">
            <div class="container">
              <p class="title-m" innerHTML="{{ 'GLOBAL.VOUCHER.VALUEX' | translate: { X: voucherCreated.amount | agoraCurrency } }}"></p>
              <p class="voucher-code">{{ voucherCreated.code }}</p>
              <div class="flex row copy-button">
                <div class="j-center">
                  <div class="icon-container flex j-end">
                    <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                      <use xlink:href="#icon-checkmark"></use>
                    </svg>
                    <button class="btn-copy" (click)="copyValue($event, voucherCreated.code)">
                      <svg viewBox="0 0 32 32" class="icon peach">
                        <use xlink:href="#icon-copy"></use>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="j-center">
                  <p>{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                </div>
              </div>
              <p class="title-m no-line-height expiration-date-label" innerHTML="{{ 'GLOBAL.VOUCHER.EXPIREATDATEX' | translate: { X: voucherCreated.expirationDate | date: 'dd/MM/yyyy' } }}"></p>
            </div>
          </div>
          <div class="footer">
            <!--<div class="action-button">
              <div class="button">
                <button class="btn-icon flex btn-primary j-center">
                  <svg viewBox="0 0 32 24" class="icon">
                    <use xlink:href="#icon-letter"></use>
                  </svg>
                </button>
              </div>
              <p>{{'GLOBAL.ACTION.SEND' | translate}}</p>
            </div>-->
            <div class="action-button">
              <div class="button">
                <button class="btn-icon btn-primary flex j-center" (click)="download(voucherCreated.code)">
                  <svg viewBox="0 0 28 29" class="icon">
                    <use xlink:href="#icon-download"></use>
                  </svg>
                </button>
              </div>
              <p>{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="back-to-wallet">
          <button class="btn btn-primary m-btn-block" (click)="closeCreatedVoucherModal()">{{ 'VOUCHER.MODAL.ALLMYVOUCHERS' | translate }}</button>
        </div>
        <a class="create-other" (click)="openCreationVoucherModal()">{{ 'VOUCHER.MODAL.CREATENEWVOUCHER' | translate }}</a>
      </div>

      <div class="modal-content d-only create-voucher-created">
        <div class="flex column ai-center">
          <h1>{{ 'GLOBAL.GREETINGS' | translate }}</h1>
          <p class="created-confirmation">{{ 'VOUCHER.MODAL.VOUCHERCREATED' | translate }}</p>
          <div class="card">
            <div class="header">
              <div class="flex row j-space-between ai-center">
                <div class="voucher-type">
                  <p>{{ 'GLOBAL.VOUCHER.TYPE_' + voucherCreated.type?.toUpperCase() | translate }}</p>
                </div>
                <div class="flex row ai-center">
                  <svg viewBox="0 0 14 14" class="icon peach status-icon">
                    <use xlink:href="#green-point"></use>
                  </svg>
                  &nbsp; {{ 'GLOBAL.STATUS.NOTUSED' | translate }}
                </div>
              </div>
            </div>
            <div class="body">
              <div class="container">
                <p class="title-m" innerHTML="{{ 'GLOBAL.VOUCHER.VALUEX' | translate: { X: voucherCreated.amount | agoraCurrency } }}"></p>
                <p class="voucher-code">{{ voucherCreated.code }}</p>
                <div class="flex row copy-button">
                  <div class="j-center">
                    <div class="icon-container flex j-end">
                      <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                        <use xlink:href="#icon-checkmark"></use>
                      </svg>
                      <button class="sm-btn-icon m-j-center copy-voucher-code" (click)="copyValue($event, voucherCreated.code)">
                        <svg viewBox="0 0 32 32" class="icon">
                          <use xlink:href="#icon-copy"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="j-center">
                    <p>{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                  </div>
                </div>
                <p class="title-m no-line-height expiration-date-label" innerHTML="{{ 'GLOBAL.VOUCHER.EXPIREATDATEX' | translate: { X: voucherCreated.expirationDate | date: 'dd/MM/yyyy' } }}"></p>
              </div>
            </div>
            <div class="footer">
              <!--<div class="action-button">
                <div class="button">
                  <button class="btn-icon flex btn-primary j-center">
                    <svg viewBox="0 0 32 24" class="icon">
                      <use xlink:href="#icon-letter"></use>
                    </svg>
                  </button>
                </div>
                <p>{{'GLOBAL.ACTION.SEND' | translate}}</p>
              </div>-->
              <div class="action-button">
                <div class="button">
                  <button class="btn-icon btn-primary flex j-center" (click)="download(voucherCreated.code)">
                    <svg viewBox="0 0 28 29" class="icon">
                      <use xlink:href="#icon-download"></use>
                    </svg>
                  </button>
                </div>
                <p>{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="back-to-wallet">
            <button class="btn btn-primary m-btn-block" (click)="closeCreatedVoucherModal()">{{ 'VOUCHER.MODAL.ALLMYVOUCHERS' | translate }}</button>
          </div>
          <a class="create-other" (click)="openCreationVoucherModal()">{{ 'VOUCHER.MODAL.CREATENEWVOUCHER' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmDeleteWishlistItem>
  <div class="modal-body confirm-delete-wishlist-item flex column ai-center">
    <p>{{ 'WISHLIST.MODAL.DELETE.CAPTION' | translate }}</p>
    <button class="action-button btn btn-primary j-center" (click)="removeItemFromWishlist()">{{ 'GLOBAL.ANSWER.YES' | translate }}</button>
    <button class="action-button btn btn-primary j-center white" (click)="modal.close('#' + id)">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
  </div>
</ng-template>

<ng-template #orderAndDeliveryModal>
  <div class="modal-body order-and-delivery">
    <p class="order-and-delivery-subtitle">{{ resourcesStore.i18n().popUpOrder.description | removeHtmlTag }}</p>
    <div class="flex column order-and-delivery-lines">
      <div class="flex row ai-center order-and-delivery-line">
        <div class="order-and-delivery-image">
          <figure>
            <img loading="lazy" class="lazy lazy--loaded" alt="" [src]="resourcesStore.i18n().popUpOrder.imageTextList[0]?.image[0]?.url" data-ll-status="loaded" />
          </figure>
        </div>
        <div class="flex column text">
          <p class="order-and-delivery-title">{{ resourcesStore.i18n().popUpOrder.imageTextList[0]?.label }}</p>
          <p class="order-and-delivery-description" [innerHTML]="resourcesStore.i18n().popUpOrder.imageTextList[0]?.text"></p>
        </div>
      </div>
      <div class="flex row ai-center order-and-delivery-line">
        <div class="order-and-delivery-image">
          <figure>
            <img loading="lazy" class="lazy lazy--loaded" alt="" [src]="resourcesStore.i18n().popUpOrder.imageTextList[1]?.image[0]?.url" data-ll-status="loaded" />
          </figure>
        </div>
        <div class="flex column text">
          <p class="order-and-delivery-title">{{ resourcesStore.i18n().popUpOrder.imageTextList[1]?.label }}</p>
          <p class="order-and-delivery-description" [innerHTML]="resourcesStore.i18n().popUpOrder.imageTextList[1]?.text"></p>
        </div>
      </div>
      <div class="flex row ai-center order-and-delivery-line">
        <div class="order-and-delivery-image">
          <figure>
            <img loading="lazy" class="lazy lazy--loaded" alt="" [src]="resourcesStore.i18n().popUpOrder.imageTextList[2]?.image[0]?.url" data-ll-status="loaded" />
          </figure>
        </div>
        <div class="flex column text">
          <p class="order-and-delivery-title">{{ resourcesStore.i18n().popUpOrder.imageTextList[2]?.label }}</p>
          <p class="order-and-delivery-description" [innerHTML]="resourcesStore.i18n().popUpOrder.imageTextList[2]?.text"></p>
        </div>
      </div>
    </div>
    <div class="flex j-center ai-center column links">
      <p>{{ resourcesStore.i18n().popUpOrder.ctaMessage | removeHtmlTag }}</p>
      <app-wavedlink [linkText]="resourcesStore.i18n().popUpOrder.buttonLabel" [externalUrl]="resourcesStore.i18n().footer.footerNav[1].children[2].navLink"></app-wavedlink>
    </div>
  </div>
</ng-template>

<ng-template #birthDateModal>
  <div class="modal-body modal-birthdate">
    <div class="flex column center-all">
      <div class="gift flex center-all">
        <svg viewBox="0 0 36 36" class="icon">
          <use xlink:href="#icon-gift"></use>
        </svg>
      </div>
      <p class="title">{{ 'STATUS.MOREPOINTS.ADD_BIRTHDATE.TITLE' | translate }}</p>
      <p class="description">{{ 'STATUS.MOREPOINTS.ADD_BIRTHDATE.LABELXPOINTS' | translate: { X: 50 } }}</p>
      <div class="input-date flex center-all">
        <form [formGroup]="birthDateForm" class="flex column center-all">
          <input class="" type="text" [inputMask]="dateInputMask" formControlName="birthDate" />
          <!-- <input class="m-only" type="date" formControlName="birthDate" id="birthDate"> -->
          <div
            class="form-error-container"
            *ngIf="bForm.birthDate.errors && (bForm.birthDate.dirty || bForm.birthDate.touched) && (bForm.birthDate.errors.dateMinimum || bForm.birthDate.errors.dateMaximum)">
            {{ 'GLOBAL.ERROR.DATERANGE' | translate }}
          </div>
        </form>
      </div>
      <div class="flex ai-center">
        <button class="btn btn-primary" (click)="addBirthDate(false)">{{ 'MODAL.BIRTHDATE.ACTION' | translate | uppercase }}</button>
        <!-- <button class="m-only btn btn-primary" (click)="addBirthDate(true)">{{'MODAL.BIRTHDATE.ACTION' | translate | uppercase}}</button> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #congratulationsModal>
  <div class="modal-body modal-congratulations">
    <div class="flex center-all header">
      <p>{{ 'MODAL.CONGRATULATION.TITLE' | translate }}</p>
    </div>
    <!--    <div class="flex column center-all" *ngIf="summary">-->
    <!--      <p class="title">{{ 'MODAL.CONGRATULATION.LEVELUP' | translate }}</p>-->
    <!--      <app-card-->
    <!--        class="card"-->
    <!--        [cardType]="cardType.STATUS"-->
    <!--        [data]="summary.status"-->
    <!--        [hiddeFooterAction]="true"-->
    <!--        [forecasts]="summary.forecasts"-->
    <!--        [showStatusLink]="false"-->
    <!--        [paddingBottom]="false"></app-card>-->
    <!--    </div>-->
    <div class="flex j-center link" *ngIf="!hiddeLink">
      <app-wavedlink
        [linkText]="'MODAL.CONGRATULATIONS.ACTION' | translate | uppercase"
        [linkUrl]="'/' + contextStore.locale() + '/club/status'"
        (click)="modal.close('#congratulationsModal')"></app-wavedlink>
    </div>
  </div>
</ng-template>

<ng-template #contextRedirectionModal>
  <div class="modal-body modal-redirection">
    <div class="flex column center-all header">
      <p>{{ 'REDIRECTION.BODY' | translate }}</p>
      <p>
        <em>{{ 'REDIRECTION.OPERATION' | translate }}</em>
      </p>
    </div>
  </div>
</ng-template>
